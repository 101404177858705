// 200829
export default {
  translation: {
    sections: {
      introduction: 'introduction',
      gallery: 'gallery',
      skills: 'skills',
      'about-me': 'about me',
      contact: 'contact',
    },
    aboutMeTextData: [
      [
        'I am currently working as a mobile developer, living in Barcelona.',
      ],
      [
        'I began my coding journey working in high tech, fabricating computer chips in a leading R&D cleanroom.',
        'I often did programming work there and took many programming courses while studying electrical engineering.',
        'I had also studied Basic and Pascal in high school.',
        'For my programming at work, I started in Pascal but taught myself C and used that extensively.',
        'In college, I studied a lot of C and some COBOL, Fortran, and assembly',
        'While at Intel, based on department needs, I wrote two programs for which I won an award and bonus.',
        'One of the things of which I am most proud in my professional life is that those programs were still in use almost a decade later.',
      ],
      [

        'I left the high tech world to pursue a career in jazz guitar.',
        'I eventually earned an MA in music and have had a fun career, but I had always missed programming, in which I had continued to dabble on the side.',
      ],
      [
        'Deciding that I need a more stable career, I returned to my programming roots.',
        'In 2016 I began the program at Free Code Camp and completed their certificates in Front End Development, Data Visualization, and Back End Development.',
        'I continue to be an active moderator and mentor on their forum and contribute to their code base as well as other open source projects.',
      ],
      [
        'After a few freelance jobs, I got a job at JB Hunt, a large US trucking company.',
        'Helping a large, diverse team develop an award winning React Native app, over two years I was promoted twice, eventually leading a team of junior developers.',
        'Among other responsibilities, I was their unit testing testing.',
        'While I liked the company and my coworkers, my wife and I dreamed of living in Barcelona.',
      ],
      [
        'My current job is as a React Native developer for IDT, working on their VoIP, messaging, and videoconferencing app.',
        'I am not looking for new opportunities.',
      ],
    ],
    introductionTextData: [
      [
        'I am a web/mobile developer specializing in React/Redux, React Native, and the MERN stack, with TypeScript.',
      ],
      [
        'I am from the US but am living in Barcelona. I am legal to work in the US and will be legal to work in Spain by the Summer of 2022.',
      ],
    ],
    skills: {
      sections: {
        frontend: 'Front End',
        backend: 'Back End',
        other: 'Other',
        past: 'Past',
      },
      text: [
        [
          "I love learning new libraries and technologies so this list is constantly changing -  don't be afraid to ask.",
        ],
        [
          "In my past life as a programmer and then hobbyist, I have also worked with Pascal, C, assembly, Fortran, COBOL, PHP, Basic, and VBA, and I've dabbled in C++ and Java."
        ],
      ],
    },
  },
};
